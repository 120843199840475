<template>
	<div class="flex">
		<LearnGroup @group-click="handleGroupClick" @group-update="getGroupData"></LearnGroup>

		<el-card shadow="never" class="content">
			<div slot="header" class="flex justify-content-s-b align-items-c">
				<span>文件列表</span>
				<div class="flex align-items-c">
					<span style="width: 70px;">文件名：</span>
					<el-input v-model="query.key" clearable size="small" class="margin-r-10" style="width: 150px;">
					</el-input>
					<el-button @click="handleSearch" type="primary" size="small">查询</el-button>
					<el-dropdown @command="handleSelectUploadType" trigger="click">
						<el-button type="primary" size="small" style="margin-left: 10px;">
							上传<i class="el-icon-arrow-down el-icon--right"></i>
						</el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item :command="uploadTypeDict.img">上传图片</el-dropdown-item>
							<el-dropdown-item :command="uploadTypeDict.file">上传文件</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<div class="group-info">
				<span style="margin-right: 30px;">组名：{{ curGroupInfo.name }}</span>
				<span style="margin-right: 30px;">总数：{{ curGroupInfo.total }}</span>
				<span style="margin-right: 30px;">启用：{{ curGroupInfo.on }}</span>
				<span>禁用：{{ curGroupInfo.off }}</span>
			</div>

			<!-- 表格 -->
			<el-table :data="tableData" v-loading="tableLoading" border stripe
				:header-cell-style="{background:'#E4E7ED',color: '#606266'}" style="width: 100%;" size="small"
				max-height="500px" class="margin-t-20">
				<el-table-column label="#" type="index" width="60"></el-table-column>
				<el-table-column label="文件名" prop="title"></el-table-column>
				<el-table-column label="文件类型" v-slot="{ row }">
					<span v-if="row.type === uploadTypeDict.img">
						<i class="el-icon-picture-outline"></i> 图片
					</span>
					<span v-else>
						<i class="el-icon-document"></i> 文件
					</span>
				</el-table-column>
				<el-table-column label="文件内容" v-slot="{ row }">
					<el-link :href="row.file" target="_blank" type="primary">查看</el-link>
				</el-table-column>
				<el-table-column label="文件大小" prop="size"></el-table-column>
				<el-table-column label="创建时间" prop="created_at" width="150px"></el-table-column>
				<el-table-column label="状态" v-slot="{ row }" width="150px">
					<el-tag v-if="row.status === 1" type="success" size="small">启用</el-tag>
					<el-tag v-else type="info" size="small">禁用</el-tag>
				</el-table-column>
				<el-table-column label="操作" fixed="right" width="120px">
					<template v-slot="{ row }">
						<div class="flex align-items-c">
							<el-dropdown :key="row.id" @command="handleTableRowOperation($event, row)" trigger="click">
								<span class="el-dropdown-link">
									更多<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="edit">编辑</el-dropdown-item>
									<el-dropdown-item command="move">移动分组</el-dropdown-item>
									<el-dropdown-item command="del">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div v-if="tableData.length" class="margin-t-10 flex">
				<el-pagination background layout="prev, pager, next, total, jumper" :total="total"
					:current-page.sync="query.page" :page-size="pageSize" @current-change="getLearnData">
				</el-pagination>
				<el-button size="small">确定</el-button>
			</div>
		</el-card>

		<!-- 上传文件的弹窗 -->
		<el-dialog :title="uploadFileDialogTitle" :visible.sync="uploadFileshowDialog" :close-on-click-modal="false"
			@close="closeUploadFileDialog" :width="uploadFileDialogWidth">
			<!-- 上传图片 -->
			<template v-if="curUploadType === uploadTypeDict.img">
				<el-form label-width="80px" :model="dialogForm">
					<el-form-item label="文件">
						<el-upload ref="fileUpload" :action="UPLOAD_URL" :auto-upload="false" :file-list="fileList"
							:before-upload="handleFileBefore" :on-success="handleFileSuccess" :on-remove="handleFileRemove"
							list-type="picture-card" accept=".png, .jpeg, .jpg">
							<i class="el-icon-plus"></i>
							<div slot="tip" style="width: 148px;">只能上传jpg/png文件</div>
						</el-upload>
					</el-form-item>
					<el-form-item label="标题">
						<el-input v-model="dialogForm.title"></el-input>
					</el-form-item>
					<el-form-item label="详情">
						<el-input v-model="dialogForm.content"></el-input>
					</el-form-item>
				</el-form>
			</template>
			<!-- 上传文件 -->
			<template v-else>
				<el-form label-width="80px" :model="dialogForm">
					<el-form-item label="文件">
						<el-upload ref="fileUpload" :action="UPLOAD_URL" :auto-upload="false" :file-list="fileList"
							:before-upload="handleFileBefore" :on-success="handleFileSuccess" :on-remove="handleFileRemove">
							<el-button size="small" type="primary">选择文件</el-button>
						</el-upload>
					</el-form-item>
					<el-form-item label="标题">
						<el-input v-model="dialogForm.title"></el-input>
					</el-form-item>
					<el-form-item label="详情">
						<el-input v-model="dialogForm.content"></el-input>
					</el-form-item>
				</el-form>
			</template>
			<span slot="footer">
				<el-button @click="closeUploadFileDialog">取消</el-button>
				<el-button :loading="fileUploading" @click="handleFileSubmit" type="primary">提交</el-button>
			</span>
		</el-dialog>

		<!-- 更换分组的弹窗 -->
		<el-dialog title="更换分组" :visible.sync="groupChangeshowDialog" @close="closeGroupChangeDialog" width="400px">
			<div class="flex">
				<p>请选择新分组：</p>
				<el-select v-model="groupValue" placeholder="请选择" class="flex-1">
					<el-option v-for="item in groupData" :key="item.id" :label="item.title" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<span slot="footer">
				<el-button @click="closeGroupChangeDialog">取消</el-button>
				<el-button :loading="groupChangeSubmitting" @click="handleGroupChangeSubmit" type="primary">确定
				</el-button>
			</span>
		</el-dialog>

		<!-- 文件（包括图片）编辑的弹窗 -->
		<el-dialog title="编辑" :visible.sync="editFileshowDialog" @close="closeFileEditDialog" width="400px">
			<div class="flex align-items-c">
				<p>文件状态：</p>
				<el-switch v-model="fileStatus" :active-value="1" :inactive-value="0" active-color="#13ce66"
					inactive-color="#ff4949" active-text="启用" inactive-text="禁用">
				</el-switch>
			</div>
			<span slot="footer">
				<el-button @click="closeFileEditDialog">取消</el-button>
				<el-button :loading="fileStatusSubmitting" @click="handleFileEditSubmit" type="primary">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import LearnGroup from './components/LearnGroup.vue'

	import {
		UPLOAD_URL,
	} from '@/utils/constant.js'

	import {
		getLearnDataAPI,
		saveLearnDataAPI,
		delLearnDataAPI,
		getGroupDataAPI,
		updateGroupOfLearnDataAPI,
		updateLearnDataFieldAPI,
	} from '@/api/learn.js'

	export default {
		name: 'Learn',
		components: {
			LearnGroup,
		},
		data() {
			return {
				/**
				 * @description 上传文件相关 
				 **/
				UPLOAD_URL,
				// 当前选择的上传类型
				curUploadType: '',
				// 上传类型
				uploadTypeDict: {
					img: 1,
					file: 2,
				},
				uploadFileDialogTitle: '',
				uploadFileDialogWidth: '',
				uploadFileshowDialog: false,
				fileList: [],
				fileUploading: false,


				// 搜索相关
				query: {
					page: 1,
					// 这个值可以看作是当前活跃的分组的id
					document_category_id: '',
					key: '',
				},


				/**
				 * @description 表格相关
				 **/
				// 分组信息，分组名、总数、启用、禁用
				curGroupInfo: {
					name: '',
					total: 0,
					on: 0,
					off: 0
				},
				tableData: [],
				total: 0,
				pageSize: 0,
				tableLoading: false,
				curRowData: null,


				/**
				 * @description 表格行操作相关 
				 **/
				// 分组更换
				groupChangeshowDialog: false,
				groupValue: '',
				groupChangeSubmitting: false,
				// 分组数据
				groupData: [],
				// 文件编辑相关
				editFileshowDialog: false,
				fileStatus: '',
				fileStatusSubmitting: false,


				// 弹窗数据内容
				dialogForm: {
					title: '',
					content: '',
				}
			}
		},
		created() {
			this.getGroupData()
		},
		methods: {
			// 分组点击
			handleGroupClick(groupId, groupName) {
				this.query.document_category_id = groupId
				this.curGroupInfo.name = groupName

				if (groupId) {
					this.getLearnData()
				} else {
					// 重置表格数据和分组数据
					this.tableData = []
					for (let key in this.curGroupInfo) {
						this.curGroupInfo[key] = 0
					}
				}
			},


			/**
			 * @description 表格顶部的操作
			 **/
			// 查询按钮
			handleSearch() {
				if (!this.query.document_category_id) return this.$message.warning('请选择分组后再进行尝试')

				this.query.page = 1
				this.getLearnData()
			},
			// 上传按钮的菜单项点击
			handleSelectUploadType(command) {
				if (!this.query.document_category_id) return this.$message.warning('请选择分组后再进行尝试')

				this.curUploadType = command
				this.uploadFileshowDialog = true

				if (command === this.uploadTypeDict.img) {
					this.uploadFileDialogWidth = '800px'
					this.uploadFileDialogTitle = '上传图片'
				} else {
					this.uploadFileDialogWidth = '500px'
					this.uploadFileDialogTitle = '上传文件'
				}
			},


			/**
			 * @description 文件上传相关
			 **/
			// 关闭上传文件的弹窗
			closeUploadFileDialog() {
				this.fileUploading = false
				this.uploadFileshowDialog = false
				this.fileList = []
			},
			// 文件上传之前
			handleFileBefore(file) {
				if (this.curUploadType === this.uploadTypeDict.img) {
					if (file.type.indexOf('png') < 0 && file.type.indexOf('jpeg') < 0) {
						this.fileUploading = false
						this.$message.error('图片类型错误')
						return false
					}
				} else {
					if (file.type.indexOf('png') > 0 || file.type.indexOf('jpeg') > 0) {
						this.fileUploading = false
						this.$message.error('请选择非图片的文件')
						return false
					}
				}
			},
			// 文件上传成功
			handleFileSuccess(res, file) {
				if (res.code !== 0) return this.$message.error(`${file.name}上传失败`)

				const data = {
					document_category_id: this.query.document_category_id,
					title: this.dialogForm.title,
					type: this.curUploadType,
					file: res.data.url,
					size: res.data.size,
					content: this.dialogForm.content,
					status: 1
				}
				saveLearnDataAPI(data).then(res => {
					this.$message.success(res.msg)
					this.closeUploadFileDialog()
					this.getLearnData()
					this.dialogForm.title = '';
					this.dialogForm.content = '';
				})
			},
			// 文件移除
			handleFileRemove(file, fileList) {
				this.fileList = fileList
			},
			// 文件上传弹窗的提交按钮
			handleFileSubmit() {
				this.fileUploading = true
				this.$refs.fileUpload.submit()
			},

			/**
			 * @description 表格行的操作
			 **/
			// 表格行操作点击
			handleTableRowOperation(command, row) {
				switch (command) {
					case 'edit':
						this.editFileshowDialog = true
						this.curRowData = row
						this.fileStatus = row.status
						break

					case 'move':
						this.groupChangeshowDialog = true
						this.curRowData = row
						break

					case 'del':
						this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							delLearnDataAPI({
								id: row.id
							}).then(res => {
								if (res.code === 0) {
									this.$message.success(res.msg)
									this.getLearnData()
								}
							})
						}).catch(() => this.$message.info('已取消删除'))
						break
				}
			},
			// 分组改变弹窗的确定按钮
			handleGroupChangeSubmit() {
				this.groupChangeSubmitting = true

				updateGroupOfLearnDataAPI({
					id: this.curRowData.id,
					document_category_id: this.groupValue
				}).then(res => {
					this.$message.success(res.msg)
					this.getLearnData()
					this.closeGroupChangeDialog()
				}).finally(() => this.groupChangeSubmitting = false)
			},
			// 关闭分组改变操作的弹窗
			closeGroupChangeDialog() {
				this.groupChangeshowDialog = false
				this.groupValue = ''
			},
			// 文件编辑的确定按钮
			handleFileEditSubmit() {
				this.fileStatusSubmitting = true

				updateLearnDataFieldAPI({
					id: this.curRowData.id,
					field: 'status',
					value: this.fileStatus
				}).then(res => {
					this.$message.success(res.msg)
					this.getLearnData()
					this.closeFileEditDialog()
				}).finally(() => this.fileStatusSubmitting = false)
			},
			// 关闭文件编辑的弹窗
			closeFileEditDialog() {
				this.editFileshowDialog = false
				this.fileStatus = ''
			},


			// 获取学习资料
			getLearnData() {
				this.tableLoading = true

				getLearnDataAPI(this.query).then(res => {
					this.tableData = res.list.data
					this.total = res.list.total
					this.pageSize = res.list.per_page
					// 设置 curGroupInfo
					this.curGroupInfo.total = res.static.status_all
					this.curGroupInfo.on = res.static.status_on
					this.curGroupInfo.off = res.static.status_off
					this.tableLoading = false
				})
			},
			// 获取分组数据
			getGroupData() {
				getGroupDataAPI({
					page: 1,
				}).then(res => this.groupData = res.data)
			}
		}
	}
</script>

<style scoped>
	.content {
		width: 100%;
		min-width: 600px;
	}

	.group-info {
		display: flex;
		padding: 8px 10px;
		background: #F1F1F1;
	}

	.el-dropdown-link {
		cursor: pointer;
		color: #409EFF;
	}

	.el-icon-arrow-down {
		font-size: 12px;
	}
</style>