// 学习教育
import request from '@/utils/util.js'

/**
 * @description 分组
 */
// 新增、编辑
export function saveGroupAPI(data) {
	return request.post('/document/cate_save', data)
}

// 列表
export function getGroupDataAPI(data) {
	return request.get('/document/cate_list', data)
}

// 删除
export function delGroupAPI(data) {
	return request.post('/document/cate_delete', data)
}


/**
 * @description 学习资料
 */
// 新增、编辑
export function saveLearnDataAPI(data) {
	return request.post('/document/save', data)
}

// 列表
export function getLearnDataAPI(data) {
	return request.get('/document/list', data)
}

// 删除
export function delLearnDataAPI(data) {
	return request.post('/document/delete', data)
}

// 分组改变
export function updateGroupOfLearnDataAPI(data) {
	return request.post('/document/change_cate', data)
}

// 编辑字段
export function updateLearnDataFieldAPI(data) {
	return request.post('/document/value_set', data)
}
