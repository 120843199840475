<template>
  <div>
    <el-card shadow="never" class="card">
      <div slot="header" class="flex justify-content-s-b align-items-c">
        <span>分组</span>
        <el-button @click="handleAdd" type="primary" icon="el-icon-plus" size="small">添加分组</el-button>
      </div>
      <div>
        <span style="color: #606266;font-size: 14px;">提示：鼠标右击分组可弹出操作菜单</span>
        <div v-loading="loading" class="group">
          <div v-for="item in groupData" :key="item.id" @click="handleGroupClick(item)"
            @contextmenu.prevent="handleMouseRightClick($event, item)" class="group-item ellipsis"
            :class="{'group-item--active': item.id === curRowId}">
            <span>
              <i v-if="item.isOpen" class="el-icon-folder-opened" style="margin-right: 5px;"></i>
              <i v-else class="el-icon-folder" style="margin-right: 5px;"></i>
              {{ item.title }}
            </span>
          </div>
        </div>
      </div>
    </el-card>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="closeDialog"
      width="550px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="分组名称" prop="title">
          <el-input v-model="form.title" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="显示顺序" prop="sort">
          <el-input v-model="form.sort" placeholder="只能是数字"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" type="textarea" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提交</el-button>
      </span>
    </el-dialog>

    <!-- 浮动的菜单 -->
    <FloatMenu ref="floatMenu" :menu="floatMenuData" @update="handleUpdateGroup" @delete="handleDeleteGroup">
    </FloatMenu>
  </div>
</template>

<script>
  /**
   * @return {Event} group-click
   * @return {Event} group-update
   **/

  import FloatMenu from '@/components/FloatMenu.vue'

  import {
    saveGroupAPI,
    getGroupDataAPI,
    delGroupAPI,
  } from '@/api/learn.js'

  export default {
    name: 'LearnGroup',
    components: {
      FloatMenu,
    },
    data() {
      return {
        floatMenuData: [{
          name: '修改',
          event: 'update'
        }, {
          name: '删除',
          event: 'delete'
        }],

        dialogTitle: '',
        showDialog: false,
        submitting: false,
        form: {
          title: '',
          sort: '',
          remark: ''
        },
        rules: {
          title: [{
            required: true,
            message: '分组名不能为空',
            trigger: 'blur'
          }],
          sort: [{
            required: true,
            message: '显示顺序不能为空',
            trigger: 'blur'
          }],
          remark: [{
            required: true,
            message: '备注不能为空',
            trigger: 'blur'
          }],
        },

        groupData: [],
        curRowId: '',
        curRowName: '',
        curRowData: null,
        loading: false,
      }
    },
    created() {
      this.getGroupData()
    },
    methods: {
      // 新增按钮
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增分组'
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      // 提交按钮
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true
          const copyForm = JSON.parse(JSON.stringify(this.form))

          if (this.dialogTitle === '修改分组') copyForm.id = this.curRowData.id
          // 提交
          saveGroupAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getGroupData()
          }).finally(() => this.submitting = false)
        })
      },
      // 鼠标右击分组项
      handleMouseRightClick(e, item) {
        this.curRowData = item
        this.$refs.floatMenu.open(e.pageX - 100, e.pageY)
      },
      // 分组项点击
      handleGroupClick(item) {
        if (this.curRowId === item.id) {
          this.curRowId = ''
          this.curRowName = ''
          item.isOpen = false
        } else {
          this.curRowId = item.id
          this.curRowName = item.title
          // 设置文件夹 icon 的状态
          this.groupData.forEach(element => {
            if (element.id === this.curRowId) element.isOpen = true
            else element.isOpen = false
          })
        }
        // 发送事件给父级
        this.$emit('group-click', this.curRowId, this.curRowName)
      },
      // 浮动菜单修改按钮
      handleUpdateGroup() {
        this.showDialog = true
        this.dialogTitle = '修改分组'

        for (let key in this.form) {
          this.form[key] = this.curRowData[key]
        }
      },
      // 浮动菜单删除按钮
      handleDeleteGroup() {
        this.$confirm('此操作将删除该分组, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delGroupAPI({
            id: this.curRowData.id
          }).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.getGroupData()
            }
          })
        })
      },
      // 获取分组数据
      getGroupData() {
        this.loading = true

        getGroupDataAPI({
          page: 1,
        }).then(res => {
          this.groupData = res.data.map(item => {
            // 如果获取分组数据的时候，有一个分组被选中，那它应该继续保持之前的选中状态
            if (item.id === this.curRowId) item.isOpen = true
            else item.isOpen = false

            return item
          })
          this.loading = false

          this.$emit('group-update')
        })
      }
    }
  }
</script>

<style scoped>
  .card {
    width: 300px;
    height: 83vh;
    overflow-y: auto;
  }

  .group {
    user-select: none;
    margin-top: 15px;
  }

  .group-item {
    padding: 8px 3px;
    color: #333;
    cursor: pointer;
  }

  .group-item:hover {
    background: #ECF5FF;
    color: #409EFF;
  }

  .group-item--active {
    background: #ECF5FF;
    color: #409EFF;
  }
</style>