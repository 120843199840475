<!-- 浮动的菜单 -->
<template>
	<div v-show="display" class="float-menu-main" :style="{left: `${x}px`,top: `${y}px`}">
		<div v-for="item in menu" :key="item.event" @click="handleEventClick(item.event)" class="float-menu-item">
			{{ item.name }}
		</div>
	</div>
</template>

<script>
	/**
	 * menu 数据的格式：
	 menu: [{
	 	name: '修改',
	 	event: 'update'
	 }]
	 **/

	export default {
		name: 'FloatMenu',
		props: {
			menu: {
				type: Array,
				default: function() {
					return []
				}
			}
		},
		data() {
			return {
				display: false,
				x: 0,
				y: 0,
			}
		},
		mounted() {
			// 默认给 menu 新增一个关闭的数据项
			this.menu.push({
				name: '关闭',
				event: 'close'
			})
		},
		methods: {
			handleEventClick(eventName) {
				if (eventName === 'close') {
					this.close()
				} else {
					this.$emit(eventName)
				}
			},
			open(x, y) {
				this.display = true
				this.x = x
				this.y = y
			},
			close() {
				this.display = false
			}
		}
	}
</script>

<style scoped>
	.float-menu-main {
		position: absolute;
		z-index: 100;
		border-radius: 4px;
		width: 100px;
		height: auto;
		background: #fff;
		text-align: center;
		transition: all .3s;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
		overflow: hidden;
	}

	.float-menu-item {
		padding: 10px;
		cursor: pointer;
	}

	.float-menu-item:hover {
		background: #BAE6FE;
	}
</style>
